import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import showNotification from '../../commons/helpers/showNotification';
import SuperUserPrivateService from '../../services/SuperUserPrivateService';

// COMPONENT
function AdmDashboardSubsPage() {
  const [dataSource, setDataSource] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const myHandleSubmit = async () => {
    try {
      setIsLoading(true);
      const resp = await SuperUserPrivateService.getSubs();

      if (resp.length === 0) {
        showNotification('warning', 'Nenhum resultado encontrado.');
      }

      setDataSource(resp);
    } finally {
      setIsLoading(false);
    }
  };

  const totalSubs = useMemo(() => {
    if (dataSource && dataSource.length > 0) {
      return dataSource.reduce((acc, current) => {
        return acc + current.count;
      }, 0);
    }
    return 0;
  }, [dataSource]);

  return (
    <>
      <Box sx={{ s: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ ml: 2 }}
            onClick={myHandleSubmit}
          >
            Buscar {isLoading && <CircularProgress />}
          </Button>
        </Box>

        <Box sx={{ mt: 1 }}>
          {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
        </Box>

        {dataSource?.length > 0 && (
          <Box sx={{ mt: 1, maxWidth: '400px' }}>
            <Typography
              component="span"
              variant="h5"
              sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
            >
              Total de Subscribers
            </Typography>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tipo de Assinatura</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource?.map((c) => {
                  return (
                    <TableRow key={c.subscriptionType ?? 'NONE'}>
                      <TableCell>{c.subscriptionType ?? 'Nenhuma'}</TableCell>
                      <TableCell>{c.count}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell>
                    <strong>TOTAL:</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{totalSubs}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
}

export default AdmDashboardSubsPage;
