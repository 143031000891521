import { Box, Button } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function AdmDashboardBasePage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      {location.pathname !== '/app/adm' && (
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="row" sx={{ mb: 5 }}>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ mt: 3 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(-1);
              }}
            >
              Voltar
            </Button>
          </Box>
        </Box>
      )}

      <Outlet />
    </div>
  );
}
